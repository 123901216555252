var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "home" } }, [
    _c("div", { staticClass: "flex flex-col items-center" }, [
      _c("div", { staticClass: "mt-8 logo-heading" }, [_vm._v("Skyhub")]),
      _c("div", { staticClass: "logo-heading" }, [
        _vm._v("Deployment Platform")
      ]),
      _vm.isSignedUp
        ? _c(
            "div",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "package.collection" } } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "mt-4 skyhub-button",
                      attrs: { type: "button" }
                    },
                    [_vm._v("Dashboard")]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isSignedUp
        ? _c(
            "div",
            [
              _c("router-link", { attrs: { to: { name: "login" } } }, [
                _c(
                  "button",
                  {
                    staticClass: "mt-2 skyhub-button",
                    attrs: { "data-cy": "login-button", type: "button" }
                  },
                  [_vm._v(" Log in ")]
                )
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.isSignedUp
        ? _c(
            "div",
            [
              _c("router-link", { attrs: { to: { name: "signup" } } }, [
                _c(
                  "button",
                  {
                    staticClass: "mt-2 skyhub-button",
                    attrs: { id: "signup", type: "button" }
                  },
                  [_vm._v(" Sign up ")]
                )
              ])
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }