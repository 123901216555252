

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SkyHubUser } from '../../models/SkyHubUser';

@Component<HomePage>({})
export default class HomePage extends Vue {
  @Prop({ default: {} as SkyHubUser })
  private currentUser!: SkyHubUser;
  @Prop({ default: false })
  private isEmailVerified!: boolean;

  get isSignedUp() {
    return this.currentUser && this.currentUser.uid;
  }
}
